// _nav.scss
/* Desktop first design */

.main-header {
  background: #ffffff;
  max-width: 1200px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  
  a {
    color: $text-color;
  }

  .hero {
    align-items: center;
    display: flex;
    justify-content: center;

    a {
      font-family: monospace, Courier, "Courier New";
      font-size:40px;
      margin: 0;
      text-decoration: none;
      width: 100%;
    }
  }

  nav {
    background-color: #fff;
    border-bottom: 2px solid $text-color;
    border-top: 2px solid $text-color;
    box-shadow: 0 8px 6px -6px $light-gray;
    height: 30px;
    transition-duration: 0.3s;
    width: 100%;

    .navbar {
      align-items: center; 
      display: flex;
      font-size: 18px;
      font-weight: 500;
      height: 100%;
      justify-content: center;
      margin: auto;

      ul {
        background-color: #ffffff;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
      }

      li {
        float: left;
        overflow-y: auto;
      }

      li a {
        align-items: center;
        display: block;
        display: flex;
        overflow: hidden;
        padding: 0px 24px;
        text-decoration: none;
      }

      li a:hover {
        position: relative;
        text-shadow: 0 0 3px $light-red;
        transition-duration: .3s;
      }

      .menu {
        clear: none;
        float: left;
        max-height: none;
      }

      /* Drop-down text */
      ul ul a {
        height: 60px;
      }

      /* Triggers drop-down */
      ul li:hover > ul {
        display:inherit;
      }

      ul ul {
        border: 1px solid $text-color;
        display: none;
        position: absolute;
        width: 220px;
      }

      /* First Tier Drop-down */
      ul ul li {
        border-bottom: 1px solid $lighter-gray;
        float: none;
      }

      ul ul li:last-child {
        border-bottom: none;
      }

      ul ul ul {
        left: 170px;
        margin-top: -66px;
      }

      /* Arrow icon pointing down */
      li > a:after {
        content:  ' \25bd';
        font-size: .7em;
        margin-bottom: -4px;
        margin-left: 3px;
      }

      /* Arrow icon pointing right */
      ul ul li > a:after {
        content:  ' \25b7';
        font-size: .7em;
        margin-bottom: -4px;
        margin-left: 3px;
      }

      li > a:only-child:after {
        content: '';
      }

      .menu-btn {
        display: none;
      }
    }
  }
}

/* Tablet menu */
@media (min-width: 601px) and (max-width: 1199px) {

  .main-header {
    .hero {
      a {
        font-size: 46px;
      }
    }
  }
}

/* Mobile menu */
@media (max-width: 600px) {

  .main-header {
    .hero {
      border-bottom: 3px solid $text-color;
      height: 64px;

      .hero-title {
        a {
          font-size: 42px;
        }
      }
    }

    nav {
      top: calc(100% - 56px);
      bottom: 0;
      position: fixed;
      height: 58px;
    }

    .navbar {
      display: block !important;
      justify-content: left;

      ul {
        border-right: 1px solid $text-color;
        border-top: 1px solid $text-color;
        display: none;
      }

      ul ul {
        bottom: -1px;
        left: 180px;
        width: calc(100vw - 180px);
      }

      ul ul li {
        margin: 0;
      }

      ul ul a {
        padding: 34px 20px;
      }

      ul ul ul {
        left: 33vw;
        width: 34vw;
      }

      li {
        float: none;
        margin: 20px 0px;
      }

      ul li:hover > ul {
        display:inherit;
        overflow: initial;
      }

      li a {
        padding: 20px 20px;
      }

      /* Arrow icon pointing right */
      li > a:after {
        content:  ' \25b7';
        font-size: .7em;
        margin-bottom: -4px;
        margin-left: 3px;
      }

      .menu {
        bottom: 52px;
        overflow: visible;
        position: absolute;
        width: 182px;
      }

      /* menu btn */
      .menu-btn {
        &:checked ~ ul {
          display: block;
        }
        &:checked ~ .menu {
          max-height: 240px;
        }
        &:checked ~ .menu-icon .navicon {
          background: transparent;
        }
        &:checked ~ .menu-icon .navicon:before {
          transform: rotate(-45deg);
        }
        &:checked ~ .menu-icon .navicon:after {
          transform: rotate(45deg);
        }
        &:checked ~ .menu-icon:not(.steps) .navicon:before,
        &:checked ~ .menu-icon:not(.steps) .navicon:after {
          top: 0;
        }
      }

      /* menu icon */
      .menu-icon {
        cursor: pointer;
        display: inline-block;
        padding: 24px 20px;
        position: relative;
        user-select: none;

        .navicon {
          background: $text-color;
          background: $text-color;
          display: block;
          height: 2px;
          position: relative;
          transition: background .2s ease-out;
          width: 18px;
        }

        .navicon:before,
        .navicon:after {
          background: $text-color;
          content: '';
          display: block;
          height: 100%;
          position: absolute;
          transition: all .3s ease-out;
          width: 100%;
        }

        .navicon:before {
          top: 5px;
        }

        .navicon:after {
          top: -5px;
        }
      }
    }
  }
  footer {
    margin-bottom: 32px !important;
  }
}
