// Typography
body {
  color: $text-color;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 2rem;
  font-weight: 300;
}

h1 {
	font-size: 3.6rem;
	line-height: 1.2;
	letter-spacing: -.1rem;
}

h2 {
	font-size: 3.0rem;
	line-height: 1.25;
	letter-spacing: -.1rem;
}

h3 {
	font-size: 2.4rem;
	line-height: 1.3;
	letter-spacing: -.1rem;
}

h4 {
	font-size: 2.0rem;
	line-height: 1.35;
	letter-spacing: -.08rem;
}

h5 {
	font-size: 1.6rem;
	line-height: 1.5;
	letter-spacing: -.05rem;
}

h6 {
	font-size: 1.2rem;
	line-height: 1.6;
	letter-spacing: 0;
}

p {
  line-height: 1.5;
}

a {
  color: $blue;
}

a:hover {
  color: $dark-blue;
}

i {
  font-style: italic;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  border-width: 0;
  border-top: 1px solid #E1E1E1;
}

code {
  background-color: #dfdfdf;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  color: #000000;
  display: inline-block;
  font-family: Operator Mono SSm A,Operator Mono SSm B,Operator Mono,Source Code Pro,Menlo,Consolas,Monaco,monospace;
  font-size: 16px;
  line-height: 1.75;
  margin: 20px 0;
  max-width: 100%;
  overflow-x: auto;
  padding: 20px 30px;
  white-space: pre;
}

.fine-print {
  font-size: .8em;
}

.bigger-text {
  font-size: 1.4em;
}
