$text-color: #2e2e2e;

$blue: #4285F4;
$red: #DB4437;
$yellow: #F4B400;;
$green: #0F9D58;;

$dark-blue: #285092;
$dark-red: #8C2820;
$dark-yellow: #7E5E03;
$dark-green: #1A542A;

$light-blue: #ccdfff;
$light-red: #ffd6d2;
$light-yellow: #ffecb4;
$light-green: #c4e6cd;

$light-gray: #aaaaaa;
$lighter-gray: #e2e2e2;
