
.realestate-content-wrapper {
  grid-template-columns: auto !important;

  .realestate-root {

    .realestate-header {
      margin: 0;
      padding-top: 10px;
      text-align: center;
      ul {
        display: inline-block;
        list-style: none;
        margin: 0;
        padding: 0;
      }
    
      li {
        display: inline-block;
        padding: 10px 20px;
      }
    
      li a {
        text-decoration: none;
      }
    
      li a:hover {
        text-decoration: underline;
      }
    }

    .heading-wrapper {
      display: inline-block;
      background: lightblue;
    }

    h1 {
      line-height: 1;
      margin-bottom: 0px;
      margin-top: 20px;
    }

    .updated {
      margin: 0 0 5px;
    }

    .adriane-block-header {
      float: right;
      margin-top: 18px;

      .title {
        font-size: 18px;
      }
    }

    #brentwood-wrapper h2 {
      background-image: url(/realestate/images/brentwood.jpg);
    }

    #malibu-wrapper h2 {
      background-image: url(/realestate/images/malibu.jpg);
    }

    #marina-del-rey-wrapper h2 {
      background-image: url(/realestate/images/marina-del-rey.jpg);
    }

    #pacific-palisades-wrapper h2 {
      background-image: url(/realestate/images/pacific-palisades.jpg);
    }

    #santa-monica-wrapper h2 {
      background-image: url(/realestate/images/santa-monica.jpg);
    }

    #venice-wrapper h2 {
      background-image: url(/realestate/images/venice.jpg);
    }

    #west-la-wrapper h2 {
      background-image: url(/realestate/images/west-la.jpg);
    }

    .line {
      display: inline-block;
    }
  }
}

.area-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 20px;
  width: 100%;

  h2 {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    font-size: 3.4rem;
    grid-column: 1 / 3;
    line-height: 0.9;
    margin: 0;
    padding: 20px 0 10px 15px;
    text-shadow: 0 0 18px black, 0 2px 0 rgba(0,0,0, .25);
  }

  .listing-card {

    .see-all-listings {
      display: none;
    }

    h3 {
      font-size: 36px;
      margin: 20px 0 5px 0;
      text-align: center;
    }

    .listing-content {
      text-align: center;

      .age {
        font-size: 0.7em;
        font-weight: bold;
        position:relative;
        top:-2px;
      }

      .price {
        color: $red;
        font-weight: bold;
      }

      button {
        cursor: pointer;
        border: none;
        color: $blue;
        font-weight: bold;
        text-decoration: underline;
        &:hover {
          color: $dark-blue;
          text-decoration: none;
        }
      }
    }
  }
}

// Realestate home mobile
@media (max-width: 800px) {
  .realestate-root {

    .adriane-block-header {
      display: grid;
      float: none;
      justify-items: center;
      margin-top: 10px !important;
      width: 100%;
    }

    h1 {
      margin-top: 15px;
      text-align: center;
    }
 
    .updated {
      margin: 0 0 5px;
      text-align: center;
      width: 100%;
    }
  }

  .area-wrapper {
    display: block !important;

    h2 {
      grid-column: 1 / 2;
    }

  }
}

// Realestate Modal
.realestate-modal {
  background-color: rgba(0,0,0,0.7);
  display: none;
  height: 100%;
  left: 0;
  overflow: auto;
  padding-top: 10vh;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10000;

  .message-success {
    background: $light-green;
    border-radius: 5px;
    color: $dark-green;
    display: block;
    font-size: 20px;
    margin-top: 5px;
    padding: 10px 15px;
    width: 100%;
  }

  .message-error {
    background: $light-red;
    border-radius: 5px;
    color: $dark-red;
    display: block;
    font-size: 20px;
    margin-top: 5px;
    padding: 10px 15px;
    width: 100%;
  }

  .error-text {
    color: $red;
    font-weight: bold;
  } 

  .modal-content {
    background-color: #fefefe;
    border-radius: 10px;
    border: 1px solid #888;
    margin: auto;
    max-width: 1000px;
    overflow: auto;
    padding: 10px 20px;
    width: 80%;

    .modal-header {

      h4 {
        font-size: 24px;
        margin: 0 0 0 0;
      }

      .close {
        color: #aaaaaa;
        float: right;
        font-size: 34px;
        font-weight: bold;
        margin: -8px -3px 0 0;
      }

      .close:hover,
      .close:focus {
        color: #000;
        cursor: pointer;
        text-decoration: none;
      }
    }

    .property-wrapper {
      display: grid;
      grid-template-columns: 50% 50%;
      overflow: auto;

      .property-photo {
        grid-column: 1 / 2;

        img {
          height: 300px;
          object-fit: contain;
          width: 100%;
        }
      }

      .property-info {
        grid-column: 2 / 3;
        padding-left: 10px;
        overflow: auto;

        .price {
          color: $red;
          float: right;
          font-size: 30px;
          font-weight: bold;
          margin: 0;
        }

        .address {
          font-size: 36px;
          margin: 22px 0 0 0;
        }

        .city-state-zip {
          font-size: 26px;
          margin: -6px 0 0 0;
        }

        .phone {
          color: $dark-blue;
          font-size: 20px;
          font-weight: bold;
          margin: 14px 0 20px 0;
        }

        .mls {
          font-size: 0.7em;
          font-weight: bold;
          margin: 0 0 6px 0;
        }

        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
        }

        li {
          float: left;
          margin: 0 10px;
          max-width: 80px;
          text-align: center;

        }
        .partial-baths {
          display: none;
        }

        .partial-bath {
          display: none;
        }

        .full-baths {
          display: none;
        }

        .full-bath {
          display: none;
        }

        .lbl {
          font-size: 0.7em;
          font-weight: bold;
        }

        .val {
          color: $dark-blue;
          font-size: 28px;
        }
      }
    }

    .tagline {
      p {
        color: $red;
        font-size: 20px;
        margin: 15px 0 10px;
        padding-left: 22px;
      }
    }

    .form-wrapper {
      background: $light-blue;
      display: grid;
      grid-template-columns: 50% 50%;
      margin-bottom: 10px;
      padding: 5px 0;

      p {
        margin-top: 5px;
        margin-bottom: 5px;
      }

      .form1 {
        grid-column: 1 / 2;
        overflow: auto;
        padding: 0 20px;

        input {
          padding-right: 20px;
          float: right;
        }

        label {
          width: auto;
          font-weight: normal;
        }

        p {
          text-align: left;
        }
      }
      .form2 {
        grid-column: 2 / 3;
        overflow: auto;
        padding: 0 20px;

        textarea {
          height: 82px;
          vertical-align: top;
          // max-width: 408px;
          width: 100%;
        }
      }
    }

    .modal-footer {
      overflow: auto;
      padding-left: 20px;

      button {
        background: $dark-blue;
        border-radius: 5px;
        border: none;
        color: #ffffff;
        cursor: pointer;
        font-size: 20px;
        margin-top: 5px;
        padding: 10px 15px;
      }

      // Adriane little block
      .adriane-little-block {
        float: right;
        margin-top: 0;

        .call {
          color: $dark-blue;
          font-size: 46px;
        }

        img {
          height: 45px;
          width: 45px;
        }

        .name-phone {
          display: inline-block;
          text-align: center;

          .name {
            font-size: 22px;
          }

          .phone {
            font-size: 26px;;
          }
        }
      }
    }
  }
}

// Modal mobile
@media (max-width: 800px) {

  .realestate-modal {
    padding-bottom: 20px;
    padding-top: 40px;

    .modal-content {
      display: grid;
      width: 90%;

      .property-wrapper {
        display: grid;
        grid-template-columns: 50% 50%;

        .property-photo {
          grid-column: 1 / 3;
        }

        .property-info {
          grid-column: 1 / 3;
        }
      }

      .form-wrapper {
        display: grid;
        grid-template-columns: 50% 50%;

        p {
          line-height: 1.1;
        }

        .form1 {
          grid-column: 1 / 3;
          text-align: center;

          label, input {
            display: block;
            float: none;
            margin: auto;
          }

          label {
            margin-bottom: 3px;;
          }

          p {
            text-align: center;
          }
        }

        .form2 {
          grid-column: 1 / 3;
        }
      }

      .modal-footer {
        padding-left: 0;
        text-align: center;

        .adriane-little-block {
          float: none;
          margin-top: 15px;
        }
      }
    }
  }
}

// Adriane block
.adriane-block {
  display: grid;
  grid-template-columns: 50px 180px 80px;
  grid-template-rows: 20px 25px 35px;

  .title {
    font-size: 22px;
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    text-align: center;
  }

  .photo {
    grid-column: 3 / 4;
    grid-row: 1 / 4;
    height: 80px;
    width: 80px;
  }

  .logo {
    font-size: 60px;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    height: 45px;
    width: 55px;
  }

  .name {
    color: $red;
    font-size: 20px;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    margin-top: 6px;
    text-align: center;
  }

  .phone {
    font-size: 24px;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    margin-top: 8px;
    text-align: center;
  }
}

// Realestate footer
.realestate-footer-menu {
  margin: 0;
  padding: 0 20px;
  text-align: center;

  ul {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    padding: 10px 20px;
  }

  li a {
    text-decoration: none;
  }

  li a:hover {
    text-decoration: underline;
  }
}

.realestate-footer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -10px;
  padding: 0 20px;
  width: 100%;

  > div {
    display: inline-block;
    margin: auto;
    min-width: 340px;
    width: 33%;
  }

  p {
      line-height: 1.2;
      margin: 0;
  }

  .mls-info {
    font-size: 10px;

    img {
      float: left;
      height: 90px;
      margin-right: 10px;
      width: 170px;
    }
  }

  .adriane-info {
    text-align: center;

    .contact-info {
      p {
        line-height: 1.6;
      }
    }
  }

  .adriane-block-footer {
    order: 1;

    .title {
      font-size: 18px;
    }
  }

  @media (max-width: 800px) {
    .mls-info {
      order: 3;
    }
  
    .adriane-info {
      padding: 20px 0;
      order: 2;
    }
  
    .adriane-block-footer  {
      order: 1;
    } 
  }
}

// Display listings in an aside on template.php
aside.realestate-root {
  .area-wrapper {
    display: block;
  }

  .listing-card {
    .see-all-listings {
      display: block;
      text-align: center;
      p {
        font-size: 14px;
        margin: 0;
      }
    }

    h3 {
      font-size: 32px;
      line-height: 1;
      margin-top: 10px;
    }
  }

  .aside-header {

    p {
      line-height: 1.5;
      margin: 0;
      margin: 5px 0;
      text-align: center;
    }

    .hotlist{
      display: block;
      margin: auto;
      width: 100px;
    }
  }
}

// Adriane ad
.adriane-ad-wrapper {
  border: 1px solid $dark-blue;
  margin-bottom: 20px;
  margin: auto;

  a {
    text-decoration: none;
  }

  p {
    color: $text-color;
    line-height: 1.2;
    margin: 5px;
    text-align: center;
  }

  .tiny-text {
    font-size: 11px;
    margin-bottom: 2px;
  }

  .adriane-ad {
    display: grid;
    grid-template-columns: 190px 80px;
    grid-template-rows: 35px 40px 40px;

    .title {
      color: $text-color;
      font-size: 24px;
      grid-column: 1 / 3;
      grid-row: 1 / 2;
      margin-top: 4px;
      text-align: center;
    }

    .photo {
      grid-column: 2 / 3;
      grid-row: 2 / 4;
      height: 80px;
      width: 80px;
    }

    .name {
      color: $red;
      font-size: 22px;
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      margin-top: 14px;
      text-align: center;
    }

    .phone {
      color: $text-color;
      font-size: 24px;
      grid-column: 1 / 2;
      grid-row: 3 / 4;
      margin-top: 8px;
      text-align: center;
    }
  }
  .blue-text {
    color: $dark-blue;
  }
}

@media (max-width: 800px) {
  .adriane-ad-wrapper  {
    display: table;
  }
}

.home-search {
  margin-bottom: 3px;
  margin-top: -5px;
  text-align: center;
}
