.weather-widget-container {
  display: grid;
  font-size: 12px;
  grid-template-columns: 6.25em 1fr;
  grid-template-rows: 1.2em 4em auto auto;
  margin: 0px auto 0px;
  max-width: 260px;
}

.weather-widget-title {
  font-size: 1.6em;
  grid-column: 1 / 3;
  margin-left: .5em;
  margin-top: 0.1em;
}

.weather-widget-icon {
  grid-column: 1 / 2;
  grid-row: 2 / 5;
  position: relative;
}

.weather-widget-icon img {
  left: -2.4em;
  position: absolute;
  top: -1.8em;
  width: 11em;
}

.weather-widget-temperature {
  font-size: 4em;
  grid-column: 2 / 3;
}

.weather-widget-condition {
  font-size: 1.6em;
  grid-column: 2 / 3;
}

.weather-widget-updated {
  font-size: .7em;
  grid-column: 2 / 3;
  margin-bottom: .1em;
  margin-top: .2em;
}
