.contact-form-wrapper {

  form {
    .form-wrapper {
      column-gap: 30px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .form1 {
        grid-column: 1 / 2;
      }
  
      .form2 {
        grid-column: 2 / 3;
      }
    }

    input, textarea {
      border-radius: 4px;
      border: 1px solid #ccc;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
      height: 34px;
      line-height: 1.5;
      padding: 6px 12px;
      width: 100%;
    }
    
    textarea {
      height: 182px;
    }
  }

  button {
    background: $dark-blue;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    float: right;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 20px;
  }

  .error-text {
    color: crimson;
  }

  .message-success {
    background: $light-green;
    border-radius: 3px;
    border: 1px solid $dark-green;
    color: $dark-green;
    display: block;
    margin-top: 60px;
    padding: 10px;
  }

  .message-error {
    background: $light-red;
    border-radius: 3px;
    border: 1px solid $dark-red;
    color: $dark-red;
    display: block;
    margin-top: 60px;
    padding: 10px;
  }
}


/* Mobile menu */
@media (max-width: 800px) {

  .contact-form-wrapper {
    form {
      .form-wrapper {
        grid-template-columns: 100%;

        .form1 {
          grid-column: 1 / 2;
        }
    
        .form2 {
          grid-column: 1 / 2;
        }
      }
    }
  }
}

// TODO - Add toast
