// _page-layout.scss
// Desktop first design

main {
  min-height: calc(100vh - 242px);
}

.page-wrapper {
  // Compensates for hero height
  background: #ffffff;
  margin: 76px auto 0; 
  max-width: 1200px;

  .heading {
    display: inline-block;
    padding: 0 20px;
    width: 100%;

    h1 {
      margin: 30px 0 -5px;
    }

    p {
      font-size: 20px;
      margin: 0;
    }

    #weather-widget {
      float: right;
      margin-top: 22px;

      .weather-widget-container {
        margin-right: 60px;
      }
    }
  }

  .content-wrapper {
    display: grid;
    grid-template-columns: auto 320px;
  }

  section {
    padding: 0 20px;

    // Responsive YouTube
    .youtube-container  { 
      height: 0;
      max-width: 100%;
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;

      iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  aside {
    padding: 20px 20px 0;
    border-left: 1px solid $lighter-gray;
  }

  .center-ad {
    padding: 0 20px;
  }

  footer {
    margin: 0;
    padding: 20px;
    text-align: center;

    ul {
      display: inline-block;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      display: inline-block;
      padding: 10px 20px;
    }

    li a {
      text-decoration: none;
    }

    li a:hover {
      text-decoration: underline;
    }
  }
}

.video-link-wrapper {
  display: inline-block;
  max-width: 540px;
  opacity: 0.8;
  position:relative;

  &:hover {
    opacity: 1.0;
  }

  .video-play-button {
    background: url(/images/play-button.png) center center no-repeat;
    height: 70px;
    left: 50%;
    margin: -35px 0 0 -35px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    width: 70px;
    // z-index: 10;
  }
}

.google-add-container {
  margin-top: -60px;
  padding-top: 20px;
}

/* Tablet */
@media (min-width: 801px) and (max-width: 1199px) {
  body {
    background-image: none;
    background-color: #ffffff;
  }

  .google-add-container {
    margin-top: 0px;
  }
}


/* Mobile */
@media (max-width: 800px) {
  body {
    background-image: none;
    background-color: #ffffff;

    .page-wrapper {
      // Compensates for change in header hero height
      margin: 64px auto 0;

      .heading {
        h1 {
          margin-top: 14px;
          text-align: center;
        }

        p {
          text-align: center;
        }

        #weather-widget {
          float: none;

          .weather-widget-container {
            border-bottom: 2px solid black;
            margin: auto;
          }
        }
      }
      main {
        margin-bottom: 60px;
        min-height: calc(100vh - 300px);
        padding-top: 0;
        
        .content-wrapper {
          display: block;
        }
      }
    }

    footer {
      margin-bottom: 40px;
    }
  }

  .google-add-container {
    margin-top: 0px;
  }
}
