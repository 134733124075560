// Box model fix:
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

img {
  width: 100%;
  height: auto;
}

button {
  background-color: #ffffff;;
}

hr {
  margin: 10px 40px;
}

.cam-container {
  margin-right: auto;
  margin-left: auto; 
}
